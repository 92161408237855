import React, { useEffect, useState }    from 'react'
import { List, XLg } from 'react-bootstrap-icons'

function Header({appTheme, setAppTheme}) {
    const [toogleState, setToogleState]     = useState(false)
    const [active, setActive]               = useState("home");

    const toogleHandler = (section) =>{
        setToogleState(!toogleState)
        setActive(section)
    }

    const [fiturPos, setFiturPos]   = useState("")
    const [temaPos, setTemaPos]     = useState("")
    const [hargaPos, setHargaPos]   = useState("")
    const [qnaPos, setQnaPos]   = useState("")

    useEffect(() => {
        var fitur   = document.getElementById("fitur&harga");
        var tema    = document.getElementById("tema");
        var qna     = document.getElementById("qna");
        setFiturPos(fitur.offsetTop)
        setTemaPos(tema.offsetTop)
        setQnaPos(qna.offsetTop)
    }, [])

    useEffect(() => {
    
        const handleScroll = () => {
            const position = window.pageYOffset;
            if(position < fiturPos){
                setActive("home")
            }else if(position > fiturPos && position < temaPos){
                setActive("fitur&harga")
            }else if(position > temaPos && position < qnaPos){
                setActive("tema")
            }else if(position > qnaPos){
                setActive("qna")
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [fiturPos, temaPos, hargaPos, qnaPos]);

    // const themeHandler = () => {
    //     appTheme === "light" ? setAppTheme("dark") : setAppTheme("light")
    // }

    return (
        <div className="header sticky-top">
            <header className='wrapper sub-header middle'>
                <h3>
                    <a href='/' className='app-link'>
                        Bikin Undangan Website
                    </a>
                </h3>
                <nav className='nav middle'>
                    <ul className='navbar-list m-0 p-0' style={{ listStyleType: "none", display: toogleState ? 'flex' : null }}>
                        <li className='navbar-list-item middle'>
                            <a 
                                href='#home' 
                                className={`app-link app-link--nav ${active === "home" ? "active" :""}`}
                                onClick={()=>toogleHandler("home")}
                            >
                                HOME
                            </a>
                        </li>
                        <li className='navbar-list-item middle'>
                            <a 
                                href='#fitur&harga' 
                                className={`app-link app-link--nav ${active === "fitur&harga" ? "active" :""}`}
                                onClick={()=>toogleHandler("fitur&harga")}
                            >
                                FITUR & HARGA
                            </a>
                        </li>
                        <li className='navbar-list-item middle'>
                            <a 
                                href='#tema' 
                                className={`app-link app-link--nav ${active === "tema" ? "active" :""}`}
                                onClick={()=>toogleHandler("tema")}
                            >
                                TEMA
                            </a>
                        </li>
                        <li className='navbar-list-item middle'>
                            <a 
                                href='#qna' 
                                className={`app-link app-link--nav ${active === "qna" ? "active" :""}`}
                                onClick={()=>toogleHandler("qna")}
                            >
                                Q&A
                            </a>
                        </li>
                        <li className='navbar-list-item'>
                            <button className='btn rounded-pill btn-outline-primary px-3'>LOGIN</button>
                        </li>
                        <li className='navbar-list-item ms-md-2'>
                            <button className='btn rounded-pill btn-primary px-3'>DAFTAR</button>
                        </li>
                    </ul>
                    {/* <button
                        onClick={themeHandler}
                        className="btn btn-toogle fs-3 ms-5"
                    >
                        {appTheme === "light" ? <Moon /> : <Sun />}
                    </button> */}
                    <button
                        onClick={toogleHandler}
                        className="btn btn-toogle navbar-toogle toogle fs-3"
                    >
                        {toogleState ? <XLg /> : <List />}
                    </button>
                </nav>
            </header>
        </div>
    )
}

export default Header