import React from 'react'

function Tema() {
    return (
        <>
            <div className="wrapper py-5" id='tema'>
                <h2 className='text-center text-primary fw-bolder mb-0'>
                    Tema
                </h2>
                <h3 className='text-center'>
                    bikinundangan.website solusinya!!!
                </h3>
            </div>
        </>
    )
}

export default Tema