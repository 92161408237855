import React from 'react'
// import { Whatsapp } from 'react-bootstrap-icons'
import banner from "../assets/img/banner.gif"
import { Whatsapp } from 'react-bootstrap-icons'

function Home() {
    return (
        <>
        
            <div className="wrapper">
                <div className="row m-0 p-0">
                    <div className="col-lg-6 col-md-12 order-lg-last text-center text-lg-end p-0">
                        <img src={banner} alt="banner" className='hero-img'/>
                    </div>
                    <div className="col-lg-6 col-md-12 order-lg-first text-center text-lg-start p-0">
                        <p className='hero-text text-primary qsb'>
                            {/* Mau Bikin Undangan Website??? */}
                            Bikin Undangan Website
                        </p>
                        <p className='sub-hero-text mb-0'>
                            Penyedia jasa undangan online gratis dan berbayar terpercaya, murah, berkualitas.
                            <br /><b> Bikin Undangan Sambil Rebahan!!!</b>
                        </p>
                        <div className='hero-btn mt-5'>
                            {/* <button className='btn rounded-pill btn-outline-success fs-5 px-5'><Whatsapp /> Konsul</button> */}
                            <a href='#fitur&harga'>
                                <button className='btn rounded-pill btn-primary bg-gradient fs-3 px-5 mb-5'>Bikin Gratis!</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>   
            <div className="bg-primary bg-gradient mt-5 text-white" id='fitur'>
                <div className="wrapper py-5">
                    <h2 className='text-center qsb fw-bolder mb-0'>
                        Mau Bikin Undangan Website ?
                    </h2>
                    <h5 className='text-center'>
                        bikinundangan.website solusinya!!!
                    </h5>
                </div>
            </div>
            <a
                href="https://wa.me/6287774291106"
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Whatsapp className='whatsapp-icon' />
            </a>
        </>
    )
}

export default Home