import React from 'react'
import { Accordion } from 'react-bootstrap'

function Qna() {
    return (
        <div className="wrapper py-5" id='qna'>
            <h2 className='text-center text-primary fw-bolder mb-3'>
                Pertanyaan yg Sering Ditanyakan
            </h2>
            <h3 className='text-center'>
            </h3>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Beneran GRATIS???</Accordion.Header>
                    <Accordion.Body>
                        Paket <b className='text-danger'> FREE</b> kami 
                        <b className='text-danger'> bener bener GRATIS</b> kak! kalau fiturnya masih kurang, 
                        kamu tinggal beli tambahan fitur yang kamu mau, atau 
                        <b className='text-primary'> upgrade</b> paket buat 
                        <b className='text-primary'> dapetin fitur premium tapi tetep MURAH!</b>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Berapa lama proses pembuatan nya?</Accordion.Header>
                    <Accordion.Body>
                        Kamu tinggal pilih paket, daftar, lakukan pembayaran (jika ada), selesai deh. Kamu tinggal edit dan sebar undanganmu sambil rebahan!
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Apakah bisa edit sendiri?</Accordion.Header>
                    <Accordion.Body>
                        Bisa banget! kami berikan fitur edit tanpa batas, jadi kamu tinggal login ke dashboard mempelai, lalu edit semua yang kamu mau!
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default Qna