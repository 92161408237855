import "./assets/css/bootstrap.css";
import { useState } from "react";
import Header       from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Snowfall from "react-snowfall";
import Tema from "./components/Tema";
import Harga from "./components/Harga";
import Qna from "./components/Qna";

// var snowflake1 = new Image();
// snowflake2.src = "./assets/img/heart.png";
// var snowflake2 = new Image();
// snowflake2.src = "./assets/img/heart.png";
// const snowflake2 = document.createElement('img')
// snowflake2.src = './assets/img/heart.png'

// const images = [snowflake1, snowflake2]


function App() {
    const [appTheme, setAppTheme] = useState("light")
    return (
        <div id="home" className="app" data-bs-theme={appTheme}>
            <Snowfall
                // Changes the snowflake color
                color="#2978b5"
                // Controls the number of snowflakes that are created (default 150)
                snowflakeCount={50}
                // Pass in the images to be used
                // images={images}
            />
            <Header appTheme={appTheme} setAppTheme={setAppTheme}/>
            <Home />
            <Harga />
            <Tema />
            <Qna />
            <Footer />
        </div>
    );
}

export default App;
