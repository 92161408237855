import React from 'react'
import { Envelope, Globe, HeartFill, Instagram, Tiktok, Whatsapp } from 'react-bootstrap-icons'
import paymentpng from '../assets/img/payment.png'

function Footer() {
    return (
        <>
            <div className="bg-primary bg-gradient mt-5 mb-3 text-white" id='fitur'>
                <div className="wrapper text-center py-5">
                    <h2 className='qsb fw-bolder mb-0'>
                        Mau Bikin Undangan Website ?
                    </h2>
                    <h5>
                        bikinundangan.website solusinya!!!
                    </h5>
                    <a href='#fitur&harga'>
                        <button className='btn rounded-pill text-primary bg-light fs-4 px-5 mt-4'>Bikin Sekarang</button>  
                    </a>
                </div>
            </div>
            <div className="wrapper">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 text-center text-md-start order-lg-1 order-md-1">
                        <p>
                            <a href='#home' className='text-primary fw-bold'>Bikin Undangan Website </a>adalah
                            penyedia jasa undangan online gratis dan berbayar yang berkualitas
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-4 col-8 mb-3 text-lg-end order-lg-3 order-md-2">
                        <p className='text-primary fw-bold mb-2'>Hubungi kami :</p>
                        <a href="/" className='text-body'><p className='mb-1 fs-7'><Instagram className='me-1 d-md-none'/> @bikinundanganwebsite       <Instagram className='ms-1 d-none d-md-inline'/> </p></a>
                        <a href="/" className='text-body'><p className='mb-1 fs-7'><Tiktok  className='me-1 d-md-none'/>   @bikinundanganwebsite       <Tiktok  className='ms-1 d-none d-md-inline'/>   </p></a>
                        <a href="/" className='text-body'><p className='mb-1 fs-7'><Globe  className='me-1 d-md-none'/>    bikinundangan.website       <Globe  className='ms-1 d-none d-md-inline'/>    </p></a>
                        <a href="/" className='text-body'><p className='mb-1 fs-7'><Envelope  className='me-1 d-md-none'/> cs@bikinundangan.website    <Envelope  className='ms-1 d-none d-md-inline'/> </p></a>
                        <a href="/" className='text-body'><p className='mb-1 fs-7'><Whatsapp  className='me-1 d-md-none'/> +62877 7429 1106            <Whatsapp  className='ms-1 d-none d-md-inline'/> </p></a>
                    </div>
                    <div className="col-lg-4 col-md-2 col-4 text-end text-md-center mb-3 order-lg-2 order-md-3">
                        <p className='text-primary fw-bold mb-2'>Halaman</p>
                        <a href='#home' className='text-body'><p className='mb-1'>Home</p></a>
                        <a href='#fitur' className='text-body'><p className='mb-1'>Fitur</p></a>
                        <a href='#tema' className='text-body'><p className='mb-1'>Tema</p></a>
                        <a href='#harga' className='text-body'><p className='mb-1'>Harga</p></a>
                        <a href='#portofolio' className='text-body'><p className='mb-1'>Portofolio</p></a>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-3 order-lg-4 order-md-4 text-md-end text-center text-lg-start">
                        <p className='text-primary fw-bold mb-2 metode-pembayaran-png'>Metode Pembayaran</p>
                        <img src={paymentpng} alt='payment' />
                    </div>
                </div>
            </div>
            <div className="bg-primary text-white pt-2 fs-6 pb-2">
                <div className="wrapper">
                    <div className="row">
                        <div className='col-md-6 col-sm-12 text-center text-md-start'>Made with <HeartFill className='text-danger'/> by Muhammad Fiqri Aulia - otodidakdeveloper.com</div>
                        <div className='col-md-6 col-sm-12 text-center text-md-end'>bikinundangan.website &copy; 2023</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer