import React from 'react'
import freePackagePng from '../assets/img/free_package.png' 
import friendlyPackagePng from '../assets/img/friendly_package.png' 
import sultanPackagePng from '../assets/img/sultan_package.png' 
import { Book, CalendarEvent, CardChecklist, Cash, ChatHeart, ClockHistory, Display, EnvelopeHeartFill, GeoAltFill, Globe, Hearts, HourglassSplit, Images, LightningFill, MusicNote, Palette, Pencil, PeopleFill, Quote, Whatsapp } from 'react-bootstrap-icons'

function Harga() {
    return (
        <div className="bg-primary bg-opacity-25">
            <div className="wrapper py-5" id='fitur&harga'>
                <h1 className='text-center qsb fw-bolder mb-0 text-primary'>
                    Bikin Sekarang<div className="d-block d-md-inline qsb text-danger"> GRATIS!</div> 
                </h1>
                <h5 className='text-center mt-3 fw-lighter text-primary'>
                    bikin pernikahanmu <div className="d-inline qsb text-success">lebih mudah</div> dan <div className="d-inline qsb text-success">lebih murah</div>
                </h5>
                <div className="row">
                    <div className="col-12">
                        <div className="card border-0 p-4 mt-3" style={{borderRadius: "30px"}}>
                            <div className="d-md-none text-center mb-4">
                                <img src={freePackagePng} alt='freepng' height='100px' width='100px'/>  
                            </div>
                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <h2 className='fw-9'>
                                        <div className="d-inline qsb text-danger">Free</div> Packages
                                    </h2>
                                </div>
                                <div>
                                    <span className="badge rounded-pill text-bg-danger text-danger middle p-2">FREE 60 Days</span>
                                </div>
                            </div>
                            Gausah bayar, tinggal daftar dan nikmati fitur-fitur gratisnya!
                            <div className="row mt-4 p-3">
                                <div className="col-lg-9 col-md-8">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-sm-12 p-0">
                                            <p className='text-primary'>
                                                <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><Cash /></span> GRATIS
                                            </p>
                                            <p className='text-primary'>
                                                <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><Pencil /></span> Edit Tanpa Batas
                                            </p>
                                            <p className='text-primary'>
                                                <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><LightningFill /></span> Instant
                                            </p>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12 p-0">
                                            <p className='text-primary'>
                                                <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><HourglassSplit /></span> Countdown Timer
                                            </p>
                                            <p className='text-primary'>
                                                <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><Palette /></span> Tema Gratis
                                            </p>
                                            <p className='text-primary'>
                                                <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><GeoAltFill /></span> Google Map
                                            </p>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12 p-0">
                                            <p className='text-primary'>
                                                <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><PeopleFill /></span> Manajemen Tamu
                                            </p>
                                            <p className='text-primary'>
                                                <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><MusicNote /></span> Music Autoplay
                                            </p>
                                            <p className='text-primary'>
                                                <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><CalendarEvent /></span> Save Event
                                            </p>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12 p-0">
                                            <p className='text-primary'>
                                                <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><Hearts /></span> Love Story
                                            </p>
                                            <p className='text-primary'>
                                                <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><Quote /></span> Quote
                                            </p>
                                            <p className='text-primary'>
                                                <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><ChatHeart /></span> RSVP & Komentar
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 text-end">
                                    <div className="d-none d-md-block d-lg-none pt-4">
                                        <img src={freePackagePng} alt='freepng' height='100px'/>
                                    </div>
                                    <div className="d-none d-lg-block">
                                        <img src={freePackagePng} alt='freepng' height='100px' style={{marginTop: '-50px'}}/>
                                    </div>
                                    <span role='button' className="badge rounded-pill text-bg-danger text-danger middle fs-5 p-3 justify-content-center mt-3">
                                        Bikin Sekarang
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card border-0 p-4 mt-3" style={{borderRadius: "30px"}}>
                            <div className="d-lg-none text-center mb-4">
                                <img src={friendlyPackagePng} alt='freepng' height='100px' width='100px'/>  
                            </div>
                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <h2 className='fw-9'>
                                        <div className="d-inline qsb text-success">Friendly</div> Packages
                                    </h2>
                                </div>
                                <div>
                                    <span className="badge rounded-pill text-bg-success text-success middle p-2">Dompet Friendly</span>
                                </div>
                            </div>
                            <div className=' z-0'>
                                Menu standar, bersahabat dengan kantong
                            </div>
                            <div className="row mt-4 p-3">
                                <div className="col-lg-7 col-md-12">
                                    <div className="row">
                                        <p className='text-primary'>
                                            FREE Packages <b>+</b>
                                        </p>
                                        <p className='text-primary'>
                                            <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><Cash /></span> Tema Premium
                                        </p>
                                        <p className='text-primary'>
                                            <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><PeopleFill /></span> 100 Daftar Tamu
                                        </p>
                                        <p className='text-primary'>
                                            <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><MusicNote /></span> Custom Music
                                        </p>
                                        <p className='text-primary'>
                                            <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><EnvelopeHeartFill /></span> Amplop Virtual
                                        </p>
                                        <p className='text-primary'>
                                            <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><ClockHistory /></span> Masa aktif 1 tahun
                                        </p>
                                        <p className='text-primary'>
                                            <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><Images /></span> Gallery
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-12 text-end">
                                    <div className="d-none d-lg-block">
                                        <img src={friendlyPackagePng} alt='freepng' height='100px' style={{marginTop: '-20px'}}/>
                                    </div>
                                    <p className='text-danger text-center text-lg-end me-lg-3  fw-bold mb-0'>Promo Opening</p>
                                    <div className="fs-1 fw-bold text-center text-lg-end me-lg-3 text-primary m-0 p-0">
                                        <p className="d-inline d-lg-block fs-5 fw-bold text-danger text-decoration-line-through m-0 p-0">Rp. 100k</p> Rp. 69k
                                    </div>
                                    <span role='button' className="badge rounded-pill text-bg-success text-success middle fs-5 p-3 justify-content-center">
                                        Bikin Sekarang
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card border-0 p-4 mt-3" style={{borderRadius: "30px"}}>
                            <div className="d-lg-none text-center mb-4">
                                <img src={sultanPackagePng} alt='freepng' height='100px' width='100px'/>  
                            </div>
                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <h2 className='fw-9'>
                                        <div className="d-inline qsb text-warning">Sultan</div> Packages
                                    </h2>
                                </div>
                                <div>
                                    <span className="badge rounded-pill text-bg-warning text-warning middle p-2">Fitur Lengkap</span>
                                </div>
                            </div>
                            <div className=' z-0'>
                                Kasta tertinggi, fitur terlengkap buat si sultan!
                            </div>
                            <div className="row mt-4 p-3">
                                <div className="col-lg-7 col-md-12">
                                    <div className="row">
                                        <p className='text-primary'>
                                            Friendly Packages <b>+</b>
                                        </p>
                                        <p className='text-primary'>
                                            <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><ClockHistory /></span> Masa aktif selamanya*
                                        </p>
                                        <p className='text-primary'>
                                            <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><PeopleFill /></span> 250 Daftar Tamu
                                        </p>
                                        <p className='text-primary'>
                                            <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><Book /></span> Buku Tamu Digital
                                        </p>
                                        <p className='text-primary'>
                                            <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><CardChecklist /></span> Check In Tamu
                                        </p>
                                        <p className='text-primary'>
                                            <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><Display /></span> Layar Sapa
                                        </p>
                                        <p className='text-primary'>
                                            <span className="badge d-inline rounded-pill text-bg-primary text-primary middle p-2 pt-1 fs-5 me-1"><Whatsapp /></span> Share Whatsapp
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-12 text-end">
                                    <div className="d-none d-lg-block">
                                        <img src={sultanPackagePng} alt='freepng' height='100px' style={{marginTop: '-20px'}}/>
                                    </div>
                                    <p className='text-danger text-center text-lg-end me-lg-3  fw-bold mb-0'>Promo Opening</p>
                                    <div className="fs-1 fw-bold text-center text-lg-end me-lg-3 text-primary m-0 p-0">
                                        <p className="d-inline d-lg-block fs-5 fw-bold text-danger text-decoration-line-through m-0 p-0">Rp. 200k</p> Rp. 125k
                                    </div>
                                    <span role='button' className="badge rounded-pill text-bg-warning text-warning middle fs-5 p-3 justify-content-center">
                                        Bikin Sekarang
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p className='text-center mt-4 mb-3 fs-5'>Kamu juga bisa tambah fitur sesuai kebutuhan lho!!!</p>
                <div className="row">
                    <div className="col-lg-2 col-md-3 col-4 mb-3">
                        <div className="card text-center p-2 fw-bold">
                            Tambah Tamu
                            <p className='mb-1'>
                                <span className="badge rounded-pill text-bg-primary text-primary fs-1 px-3 my-2">
                                    <PeopleFill />
                                </span>
                            </p>
                            100 / 25k
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-4 mb-3">
                        <div className="card text-center p-2 fw-bold">
                            Tema Premium
                            <p className='mb-1'>
                                <span className="badge rounded-pill text-bg-primary text-primary fs-1 px-3 my-2">
                                    <Palette />
                                </span>
                            </p>
                            25k
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-4 mb-3">
                        <div className="card text-center p-2 fw-bold">
                            Buku Tamu
                            <p className='mb-1'>
                                <span className="badge rounded-pill text-bg-primary text-primary fs-1 px-3 my-2">
                                    <Book />
                                </span>
                            </p>
                            30k
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-4 mb-3">
                        <div className="card text-center p-2 fw-bold">
                            Share Whatsapp
                            <p className='mb-1'>
                                <span className="badge rounded-pill text-bg-primary text-primary fs-1 px-3 my-2">
                                    <Whatsapp />
                                </span>
                            </p>
                            30k
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-4 mb-3">
                        <div className="card text-center p-2 fw-bold">
                            Wedding Planner
                            <p className='mb-1'>
                                <span className="badge rounded-pill text-bg-primary text-primary fs-1 px-3 my-2">
                                    <CardChecklist />
                                </span>
                            </p>
                            100k
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-4 mb-3">
                        <div className="card text-center p-2 fw-bold">
                            Custom Domain
                            <p className='mb-1'>
                                <span className="badge rounded-pill text-bg-primary text-primary fs-1 px-3 my-2">
                                    <Globe />
                                </span>
                            </p>
                            300k
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Harga